<template>
  <div>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          outlined
          x-small
          color="green"
          @click="dialog = true"
          ><v-icon small>file_upload</v-icon></v-btn
        >
      </template>
      <span>Enviar de documentos</span>
    </v-tooltip>
    <v-dialog v-model="dialog" width="1100">
        <v-card class="py-3">
          <v-card-title>
            <v-row wrap>
              <v-col cols="6" class="text-right">
                <div>Enviar Documentos</div>
              </v-col>
              <v-col cols="6" class="text-left">
                <v-btn x-small @click="removeFilesToUpload()"><v-icon small>remove</v-icon> </v-btn>
                <v-btn x-small @click="addFilesToUpload()" class="mr-1"><v-icon small >add</v-icon> </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" class="text-center" v-for="number in numberFiles" :key="number.id">
                <SingleFileUpload></SingleFileUpload>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-container>
              <v-row justify="center" align="start">
                <v-col cols="12" class="text-center">
                  <v-btn
                    color="red"
                    outlined
                    @click="dialog = false"
                    >Sair <v-icon>close</v-icon></v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SingleFileUpload from './SingleFileUpload'

export default {
  name: "FileUpload",
  components: { SingleFileUpload },
  data() {
    return {
      numberFiles: [
        {
          id: new Date().getMilliseconds()/3.14,
        }
      ],
      dialog: false,
    };
  },

  created() {},

  methods: {
      addFilesToUpload(){
        this.numberFiles.push(
          {
            id: new Date().getMilliseconds()/7.93,
          }
        );
      },

      removeFilesToUpload(){
        this.numberFiles.pop();
      }
  },

};
</script>

<style>
</style>