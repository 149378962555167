<template>
  <div>
    <v-row wrap class="pa-0">
      <v-col cols="12" sm="12" md="5">
        <v-file-input
          dense
          outlined
          v-model="file"
          :rules="rules"
          persistent-hint
          label="Documento"
          hint="Arquivo .pdf"
          placeholder="Documento"
          accept="application/pdf"
        ></v-file-input>
      </v-col>
      <v-col cols="12" sm="12" :md="progress === true ? 3 : 4">
        <v-autocomplete
          dense
          outlined
          counter="50"
          :items="types"
          persistent-hint
          v-model="fileType"
          label="Tipo de documento"
          placeholder="Tipo de Documento"
          no-data-text="Nenhum tipo informado"
          hint="Se não estiver na lista, informar manualmente."
        ></v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        :md="progress === true ? 2 : 3"
        class="text-start"
      >
        <v-btn
          outlined
          color="green"
          :disabled="file === null || fileType === null"
          @click="postAsyncUploadFile()"
          >Enviar<v-icon color="green" class="ml-2">file_upload</v-icon></v-btn
        >
      </v-col>
      <v-col cols="12" sm="12" :md="progress === true ? 2 : 0" v-if="progress">
        <v-progress-circular
          indeterminate
          color="green"
          v-show="progress"
        ></v-progress-circular>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ snackBarMessage }}
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "SingleFileUpload",
  props: [],
  components: {},
  data() {
    return {
      types: [
        "Artigo",
        "Carta de Recomendação",
        "Histórico",
        "Relatório Técnico",
        "Trabalho de Conclusão de Curso",
      ],
      file: null,
      fileType: null,
      progress: false,
      snackbar: false,
      timeout: 3000,
      snackBarMessage: "",
      rules: [
        (file) =>
          !file ||
          file.size < 8000000 ||
          "O Arquivo deve ter menos que 8MB de tamanho!",
        (file) =>
          !file ||
          file.type === "application/pdf" ||
          `O arquivo não é do tipo pdf!!!`,
      ],
    };
  },

  methods: {
    ...mapActions("file", ["ActionUploadFile"]),

    async postAsyncUploadFile() {

      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("file_type", this.fileType);

      try {
        this.progress = true;
        await this.ActionUploadFile(formData);
        this.snackbar = true;
        this.snackBarMessage = `Arquivo ${this.file.name} salvo com sucesso!`;
        this.dialog = false;
        this.file = null;
        this.fileType = "";
      } catch (e) {
        console.error(e);
        this.snackbar = true;
        this.snackBarMessage = `Erro ao salvar o arquivo ${this.file.name}! Tente novamente...`;
      } finally {
        this.progress = false;
      }
    },
  },
};
</script>

<style>
</style>