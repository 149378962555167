var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-tooltip",
        {
          attrs: { top: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            outlined: "",
                            "x-small": "",
                            color: "green"
                          },
                          on: {
                            click: function($event) {
                              _vm.dialog = true
                            }
                          }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v("file_upload")
                      ])
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [_c("span", [_vm._v("Enviar de documentos")])]
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "1100" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "py-3" },
            [
              _c(
                "v-card-title",
                [
                  _c(
                    "v-row",
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "text-right", attrs: { cols: "6" } },
                        [_c("div", [_vm._v("Enviar Documentos")])]
                      ),
                      _c(
                        "v-col",
                        { staticClass: "text-left", attrs: { cols: "6" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { "x-small": "" },
                              on: {
                                click: function($event) {
                                  return _vm.removeFilesToUpload()
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v("remove")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-1",
                              attrs: { "x-small": "" },
                              on: {
                                click: function($event) {
                                  return _vm.addFilesToUpload()
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v("add")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    _vm._l(_vm.numberFiles, function(number) {
                      return _c(
                        "v-col",
                        {
                          key: number.id,
                          staticClass: "text-center",
                          attrs: { cols: "12" }
                        },
                        [_c("SingleFileUpload")],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        { attrs: { justify: "center", align: "start" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "text-center",
                              attrs: { cols: "12" }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "red", outlined: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.dialog = false
                                    }
                                  }
                                },
                                [
                                  _vm._v("Sair "),
                                  _c("v-icon", [_vm._v("close")])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }