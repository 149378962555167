var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "pa-0", attrs: { wrap: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "12", md: "5" } },
            [
              _c("v-file-input", {
                attrs: {
                  dense: "",
                  outlined: "",
                  rules: _vm.rules,
                  "persistent-hint": "",
                  label: "Documento",
                  hint: "Arquivo .pdf",
                  placeholder: "Documento",
                  accept: "application/pdf"
                },
                model: {
                  value: _vm.file,
                  callback: function($$v) {
                    _vm.file = $$v
                  },
                  expression: "file"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              attrs: { cols: "12", sm: "12", md: _vm.progress === true ? 3 : 4 }
            },
            [
              _c("v-autocomplete", {
                attrs: {
                  dense: "",
                  outlined: "",
                  counter: "50",
                  items: _vm.types,
                  "persistent-hint": "",
                  label: "Tipo de documento",
                  placeholder: "Tipo de Documento",
                  "no-data-text": "Nenhum tipo informado",
                  hint: "Se não estiver na lista, informar manualmente."
                },
                model: {
                  value: _vm.fileType,
                  callback: function($$v) {
                    _vm.fileType = $$v
                  },
                  expression: "fileType"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "text-start",
              attrs: { cols: "12", sm: "12", md: _vm.progress === true ? 2 : 3 }
            },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    outlined: "",
                    color: "green",
                    disabled: _vm.file === null || _vm.fileType === null
                  },
                  on: {
                    click: function($event) {
                      return _vm.postAsyncUploadFile()
                    }
                  }
                },
                [
                  _vm._v("Enviar"),
                  _c(
                    "v-icon",
                    { staticClass: "ml-2", attrs: { color: "green" } },
                    [_vm._v("file_upload")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.progress
            ? _c(
                "v-col",
                {
                  attrs: {
                    cols: "12",
                    sm: "12",
                    md: _vm.progress === true ? 2 : 0
                  }
                },
                [
                  _c("v-progress-circular", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.progress,
                        expression: "progress"
                      }
                    ],
                    attrs: { indeterminate: "", color: "green" }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.timeout },
          model: {
            value: _vm.snackbar,
            callback: function($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar"
          }
        },
        [_vm._v(" " + _vm._s(_vm.snackBarMessage) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }